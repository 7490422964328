@import '../../variables.scss';

.Busy {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 15px;

    div {
        position: absolute;
        top: 3px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: $primary;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 8px;
            animation: busy-1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 8px;
            animation: busy-2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 32px;
            animation: busy-2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 56px;
            animation: busy-3 0.6s infinite;
        }
    }
}

@keyframes busy-1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes busy-3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes busy-2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
