@import '../../variables.scss';

.LessonView {
    padding: 1rem;
    position: relative;
    flex: 1;

    &.is--busy {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    h3 {
        margin-top: 0;
    }

    #bulk-progress {
        margin: 1rem 0;
        display: block;
        width: 100%;
        background-color: $dark-grey;
        border-radius: 5px;
        border: none;
        padding: 0.5rem 1rem;
        color: #FFFFFF;
        font-weight: 500;
        & + button {
            background-color: $primary;
            margin-left: 1rem;
        }
    }

    .Back {
        font-size: 14px;
        text-decoration: none;
        color: #000;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        span {
            background-color: $primary;
            color: #FFFFFF;
            width: 25px;
            height: 25px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 35%;
            vertical-align: 0;
            margin: 0 8px 0 0;
            svg {
                width: 8px;
                height: 8px;
                transform: scale(-1);
            }
        }
    }

    .CriteriaModal {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        background-color: #FFF;

        .Back {
            margin-top: 1rem;
        }
    }

    .BulkProgressModal {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        background-color: #FFF;

        .table-responsive {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            margin: 0 0 1rem;
            table {
                border-collapse: collapse;
                width: 100%;
                table-layout: auto;
                thead {
                    tr {
                        th {
                            text-align: left;
                            padding-bottom: 5px;
                            border-bottom: solid 1px $dark-grey;
                            vertical-align: bottom;
                            font-size: 14px;
                            &:not(:last-child) {
                                padding-right: 1rem;
                            }
                            &.criteria {
                                width: 50%;
                            }
                        }
                    }
                }
                .badge {
                    text-align: left;
                    font-size: 14px;
                    padding-top: 1rem;
                    padding-bottom: 5px;
                    border-bottom: solid 1px $dark-grey;
                }
                tbody {
                    tr {
                        td {
                            font-size: 14px;
                            padding: 5px 0;
                            border-bottom: solid 1px rgba($dark-grey, 0.35);
                            &:not(:last-child) {
                                padding-right: 1rem;
                            }
                            input {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    .MoveModal {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        background-color: #FFF;

        dl {
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            dt {
                flex-basis: 35%;
                font-weight: bold;
                padding: 3px 0;
            }
            dd {
                flex-basis: 65%;
                margin-inline-start: 0;
                padding: 3px 0;
            }
        }

        select {
            display: block;
            width: 100%;
            margin-bottom: 0.5rem;
            & + button {
                margin: 0 0 0 auto;
                display: block;
                background-color: $dark-grey;
                border-radius: 5px;
                border: none;
                padding: 0.5rem 1rem;
                color: #FFFFFF;
                font-weight: 500;
                &:disabled {
                    opacity: 0.5;
                }
            }
        }

        .error {
            padding: 15px 25px;
            color: #721c24;
            background-color: #f8d7da;
            border: solid 1px #f5c6cb;
            border-radius: 5px;
            margin: -0.5rem 0 1rem;
        }

        .Back {
            margin-top: 1rem;
        }
    }

    .RescheduleModal {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;
        background-color: #FFF;

        dl {
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            dt {
                flex-basis: 35%;
                font-weight: bold;
                padding: 3px 0;
            }
            dd {
                flex-basis: 65%;
                margin-inline-start: 0;
                padding: 3px 0;
            }
        }

        select, input[type="date"] {
            display: block;
            width: 100%;
            margin-bottom: 0.5rem;
            & + button {
                margin: 0 0 0 auto;
                display: block;
                background-color: $dark-grey;
                border-radius: 5px;
                border: none;
                padding: 0.5rem 1rem;
                color: #FFFFFF;
                font-weight: 500;
                &:disabled {
                    opacity: 0.5;
                }
            }
        }

        .error {
            padding: 15px 25px;
            color: #721c24;
            background-color: #f8d7da;
            border: solid 1px #f5c6cb;
            border-radius: 5px;
            margin: -0.5rem 0 1rem;
        }

        .Back {
            margin-top: 1rem;
        }
    }

    .LessonViewDetail {
        margin-bottom: 1rem;

        .LessonAttendanceRow {
            margin-bottom: 1px;
            .LessonAttendanceRowHeader {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                background-color: $primary-light;
                padding: 0.5rem;

                .name {
                    font-size: 12px;
                    font-weight: bold;
                    margin-right: auto;
                }

                .unpaid {
                    color: red;
                }

                .discs {
                    font-size: 12px;
                    font-weight: bold;
                    margin-right: 1rem;
                }

                .toggle {
                    border: none;
                    background: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 25px;
                    height: 25px;
                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
            .LessonAttendanceRowBody {
                display: none;
                padding: 1rem 0.5rem;

                a {
                    text-decoration: none;
                    color: $primary;
                }

                dl {
                    font-size: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 0;
                    dt {
                        flex-basis: 35%;
                        font-weight: bold;
                        padding: 3px 0;
                    }
                    dd {
                        flex-basis: 65%;
                        margin-inline-start: 0;
                        padding: 3px 0;
                    }
                }

                button {
                    margin: 1rem 0 0;
                    display: inline-block;
                    background-color: $dark-grey;
                    border-radius: 5px;
                    border: none;
                    padding: 0.5rem 1rem;
                    color: #FFFFFF;
                    font-weight: 500;
                    & + button {
                        background-color: $primary;
                        margin-left: 1rem;
                    }
                }

                label {
                    margin: 1rem 0 0.5rem;
                    padding: 0;
                    display: block;
                    font-size: 12px;
                }

                input[type="text"] {
                    width: 100%;
                    padding: 0.5rem;
                }

                select {
                    width: 100%;
                    padding: 0.5rem;
                }

                textarea {
                    width: 100%;
                    padding: 0.5rem;
                }
            }
            &.open {
                .LessonAttendanceRowHeader {
                    .toggle {
                        svg {
                            transform: rotate(-90deg);
                        }
                    }
                }
                .LessonAttendanceRowBody {
                    display: block;

                }
            }
        }
    }

    .LessonCriteriaRow {
        margin-bottom: 1px;
        .LessonCriteriaRowHeader {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            background-color: $primary-light;
            padding: 0.5rem;

            .name {
                font-size: 12px;
                font-weight: bold;
                margin-right: auto;
            }

            .toggle {
                border: none;
                background: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                svg {
                    transform: rotate(90deg);
                }
            }
        }
        .LessonCriteriaRowBody {
            display: none;
            padding: 1rem 0.5rem;

            textarea {
                width: 100%;
                padding: 0.5rem;
            }
        }
        &.open {
            .LessonCriteriaRowHeader {
                .toggle {
                    svg {
                        transform: rotate(-90deg);
                    }
                }
            }
            .LessonCriteriaRowBody {
                display: block;

            }
        }
    }
}
